import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageTypeConsumer } from "../components/context/page-type";

// Components
import FullVideo from "../components/videos/full-video";

const SingleVideoContent = ({ data, setPageType, location }) => {
  useEffect(() => {
    setPageType("video");
  }, []);

  let nextVideoUid = null;
  let prevVideoUid = null;

  // Default Back Button And Video Location Links
  let generateBackButton = `/film`;
  let generateVideoLocation = `/film`;

  if (
    location !== undefined &&
    location !== null &&
    location.state !== undefined &&
    location.state !== null
  ) {
    generateVideoLocation = location.state.videoLocation;
    const currentPathname = location.pathname;

    if (location.state.videoLocation === "production") {
      generateBackButton = `/film`;

      const allGalleryVideoUids = data.prismicDirectors.data.full_width_carousel.map(
        video => {
          const videoUrl = `${video.video.uid}`;
          return videoUrl;
        }
      );

      const allContentVideoUids = data.prismicDirectors.data.directors.map(
        video => {
          const videoUrl = `${video.video.uid}`;
          return videoUrl;
        }
      );

      const allProductionPageUids = allGalleryVideoUids.concat(
        allContentVideoUids
      );

      const videoPositionInReel = allProductionPageUids.indexOf(
        data.prismicVideo.uid
      );
      const numberOfVideosInReel = allProductionPageUids.length;

      let nextVideoPositionInReel;
      let prevVideoPositionInReel;
      if (allProductionPageUids !== -1) {
        // Video exists
        nextVideoPositionInReel = videoPositionInReel + 1;
        prevVideoPositionInReel = videoPositionInReel - 1;
      } else {
        nextVideoPositionInReel = false;
        prevVideoPositionInReel = false;
      }

      if (nextVideoPositionInReel >= numberOfVideosInReel) {
        nextVideoPositionInReel = 0;
      }
      if (prevVideoPositionInReel < 0) {
        prevVideoPositionInReel = numberOfVideosInReel - 1;
      }

      if (
        nextVideoPositionInReel !== false &&
        prevVideoPositionInReel !== false
      ) {
        nextVideoUid = `/${allProductionPageUids[nextVideoPositionInReel]}`;
        prevVideoUid = `/${allProductionPageUids[prevVideoPositionInReel]}`;
      } else {
        nextVideoUid = false;
        prevVideoUid = false;
      }
    }

    if (location.state.videoLocation === "directors") {
      generateBackButton = `/directors/${data.prismicVideo.data.director.document.uid}`;

      const allDirectorVideos = data.prismicVideo.data.director.document.data.videos.map(
        video => video.video.uid
      );
      const videoPositionInReel = allDirectorVideos.indexOf(
        data.prismicVideo.uid
      );
      const numberOfVideosInReel = allDirectorVideos.length;

      let nextVideoPositionInReel;
      let prevVideoPositionInReel;
      if (allDirectorVideos !== -1) {
        // Video exists
        nextVideoPositionInReel = videoPositionInReel + 1;
        prevVideoPositionInReel = videoPositionInReel - 1;
      } else {
        nextVideoPositionInReel = false;
        prevVideoPositionInReel = false;
      }

      if (nextVideoPositionInReel >= numberOfVideosInReel) {
        nextVideoPositionInReel = 0;
      }
      if (prevVideoPositionInReel < 0) {
        prevVideoPositionInReel = numberOfVideosInReel - 1;
      }

      if (
        nextVideoPositionInReel !== false &&
        prevVideoPositionInReel !== false
      ) {
        nextVideoUid = `/${allDirectorVideos[nextVideoPositionInReel]}`;
        prevVideoUid = `/${allDirectorVideos[prevVideoPositionInReel]}`;
      } else {
        nextVideoUid = false;
        prevVideoUid = false;
      }
    }

    if (location.state.videoLocation === "food-and-drink") {
      generateBackButton = `/food-and-drink`;

      const allGalleryVideoUids = data.prismicFoodDirectors.data.full_width_carousel.map(
        video => {
          const videoUrl = `${video.video.uid}`;
          return videoUrl;
        }
      );

      const allContentVideoUids = data.prismicFoodDirectors.data.directors.map(
        video => {
          const videoUrl = `${video.video.uid}`;
          return videoUrl;
        }
      );

      const allFoodAndDrinkVideoUids = allGalleryVideoUids.concat(
        allContentVideoUids
      );

      const videoPositionInReel = allFoodAndDrinkVideoUids.indexOf(
        data.prismicVideo.uid
      );
      const numberOfVideosInReel = allFoodAndDrinkVideoUids.length;

      let nextVideoPositionInReel;
      let prevVideoPositionInReel;
      if (allFoodAndDrinkVideoUids !== -1) {
        // Video exists
        nextVideoPositionInReel = videoPositionInReel + 1;
        prevVideoPositionInReel = videoPositionInReel - 1;
      } else {
        nextVideoPositionInReel = false;
        prevVideoPositionInReel = false;
      }

      if (nextVideoPositionInReel >= numberOfVideosInReel) {
        nextVideoPositionInReel = 0;
      }
      if (prevVideoPositionInReel < 0) {
        prevVideoPositionInReel = numberOfVideosInReel - 1;
      }

      if (
        nextVideoPositionInReel !== false &&
        prevVideoPositionInReel !== false
      ) {
        nextVideoUid = `/${allFoodAndDrinkVideoUids[nextVideoPositionInReel]}`;
        prevVideoUid = `/${allFoodAndDrinkVideoUids[prevVideoPositionInReel]}`;
      } else {
        nextVideoUid = false;
        prevVideoUid = false;
      }
    }

    if (location.state.videoLocation === "events") {
      generateBackButton = `/events`;

      const allExperientialVideoUids = data.prismicExperiential.data.body
        .filter(video => video.slice_type === "video")
        .map(video => {
          const videoUrl = `${video.primary.video.uid}`;
          return videoUrl;
        });

      const videoPositionInReel = allExperientialVideoUids.indexOf(
        data.prismicVideo.uid
      );
      const numberOfVideosInReel = allExperientialVideoUids.length;

      let nextVideoPositionInReel;
      let prevVideoPositionInReel;
      if (allExperientialVideoUids !== -1) {
        // Video exists
        nextVideoPositionInReel = videoPositionInReel + 1;
        prevVideoPositionInReel = videoPositionInReel - 1;
      } else {
        nextVideoPositionInReel = false;
        prevVideoPositionInReel = false;
      }

      if (nextVideoPositionInReel >= numberOfVideosInReel) {
        nextVideoPositionInReel = 0;
      }
      if (prevVideoPositionInReel < 0) {
        prevVideoPositionInReel = numberOfVideosInReel - 1;
      }

      if (
        nextVideoPositionInReel !== false &&
        prevVideoPositionInReel !== false
      ) {
        nextVideoUid = `/${allExperientialVideoUids[nextVideoPositionInReel]}`;
        prevVideoUid = `/${allExperientialVideoUids[prevVideoPositionInReel]}`;
      } else {
        nextVideoUid = false;
        prevVideoUid = false;
      }
    }
  } else {
    generateBackButton = `/film`;
    generateVideoLocation = `/film`;
  }

  const director =
    data.prismicVideo.data.director.document !== null
      ? data.prismicVideo.data.director.document.data.title.html
      : "";

  const directorUid =
    data.prismicVideo.data.director.document !== null
      ? data.prismicVideo.data.director.document.uid
      : null;

  return (
    <>
      <Helmet
        title={`${data.prismicVideo.data.title.text} – Park Village`}
        meta={[
          {
            name: "og:title",
            content: `${data.prismicVideo.data.title.text} – Park Village`,
          },
          {
            name: "twitter:title",
            content: `${data.prismicVideo.data.title.text} – Park Village`,
          },
        ]}
      />

      <FullVideo
        videoDetails={data.prismicVideo.data}
        video={data.prismicVideo.data.vimeo_video.html}
        width={data.prismicVideo.data.vimeo_video.width}
        height={data.prismicVideo.data.vimeo_video.height}
        playerId={`single_director_video_${data.prismicVideo.prismicId}`}
        embed_url={data.prismicVideo.data.vimeo_video.embed_url}
        prismicThumbnail={data.prismicVideo.data.thumbnail.fluid}
        director={director}
        directorUid={generateVideoLocation !== `events` ? directorUid : null}
        brand={data.prismicVideo.data.brand.text}
        clip={data.prismicVideo.data.title.text}
        nextVideoUid={nextVideoUid}
        prevVideoUid={prevVideoUid}
        backButtonURL={generateBackButton}
        generateVideoLocation={generateVideoLocation}
        filteredVideos={
          location !== undefined &&
          location !== null &&
          location.state !== undefined &&
          location.state !== null &&
          location.state.filteredVideos
        }
      />
    </>
  );
};

const SingleVideo = ({ data, location }) => (
  <PageTypeConsumer>
    {({ setPageType }) => (
      <SingleVideoContent
        setPageType={setPageType}
        data={data}
        location={location}
      />
    )}
  </PageTypeConsumer>
);

export default withPreview(SingleVideo);

export const query = graphql`
  query SingleVideo($uid: String!) {
    prismicVideo(uid: { eq: $uid }) {
      uid
      prismicId
      data {
        title {
          text
        }
        brand {
          text
        }
        thumbnail {
          alt
          url
          fluid(imgixParams: { w: 2000 }) {
            srcWebp
            srcSetWebp
          }
        }
        director {
          document {
            ... on PrismicDirector {
              id
              uid
              data {
                title {
                  html
                }
                videos {
                  video {
                    uid
                  }
                }
              }
            }
          }
        }
        vimeo_video {
          width
          title
          description
          embed_url
          height
          html
        }
        body {
          ... on PrismicVideoBodyImage {
            id
            slice_type
            primary {
              image {
                fluid {
                  srcWebp
                  srcSetWebp
                }
                alt
                dimensions {
                  height
                  width
                }
              }
            }
          }
          ... on PrismicVideoBodyText {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
        }
      }
    }
    prismicExperiential {
      data {
        body {
          ... on PrismicExperientialBodyVideo {
            id
            slice_type
            primary {
              video {
                uid
              }
            }
          }
        }
      }
    }
    prismicDirectors {
      data {
        full_width_carousel {
          video {
            uid
          }
        }
        directors {
          video {
            uid
          }
        }
      }
    }
    prismicFoodDirectors {
      data {
        full_width_carousel {
          video {
            uid
          }
        }
        directors {
          video {
            uid
          }
        }
      }
    }
  }
`;
